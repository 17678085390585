import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Modal, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { ReactElement } from 'react'
import { GraphConfig } from '../lib/storage'

export default function EditGraphModal({
  isOpen,
  onOk,
  onCancel,
  config
}: {
  isOpen: boolean
  onOk: (lines: { lines: GraphConfig['lines'] }) => void
  onCancel: () => void
  config: GraphConfig
}): ReactElement {
  const [form] = useForm()

  function handleOk() {
    onOk(form.getFieldsValue())
  }

  function handleCancel() {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      width={890}
      title="Graph Konfiguration bearbeiten"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Speichern"
      cancelText="Abbrechen"
    >
      <Form
        form={form}
        name="edit"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ marginTop: 20 }}
        initialValues={config}
        onFinish={handleOk}
        autoComplete="off"
      >
        <Form.Item name="timespan" label="Anzeigefenster (Tage)" labelCol={{ span: 5 }} layout="horizontal">
          <Input placeholder="14" style={{ width: 50 }} />
        </Form.Item>
        <Form.List name="lines">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'label']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Bitte einen Bezeichner für die Linie eingeben'
                      }
                    ]}
                    noStyle
                  >
                    <Input placeholder="Bezeichner" style={{ width: 250 }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'color']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: false,
                        message: 'Bitte eine Farbe eingeben'
                      }
                    ]}
                    noStyle
                  >
                    <Input placeholder="Farbe" style={{ width: 100 }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'property']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: false,
                        message: 'Bitte den Property-Namen eingeben'
                      }
                    ]}
                    noStyle
                  >
                    <Input placeholder="Property-Name" style={{ width: 100 }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'filterCondition']}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                        message: 'Bitte eine Filterbedingung auswählen'
                      }
                    ]}
                    noStyle
                  >
                    <Input placeholder="Filter" style={{ width: 300 }} />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'binary']} valuePropName="checked" noStyle>
                    <Checkbox style={{ width: 40 }} />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                  ) : null}
                </Space>
              ))}
              <Form.Item>
                <Button type="default" onClick={() => add()} style={{ width: '60%' }} icon={<PlusOutlined />}>
                  Feld hinzufügen
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}
