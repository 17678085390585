import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Main } from './components/Main'
import { getConfigs, setConfigs } from './lib/storage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/:tab',
    element: <Main />,
  },
])

function App() {
  return <RouterProvider router={router} />
}

declare namespace window {
  export let shareConfig: () => void
  export let resetConfig: () => void
}

window.resetConfig = () => {
  setConfigs([])
}

window.shareConfig = () => {
  const configs = getConfigs()
  console.log(`?cfg=${encodeURIComponent(JSON.stringify(configs))}`)
}

export default App
