import format from 'date-fns/format'
import { omit } from 'lodash'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { getChangedEvents, getRecentEvents } from '../lib/dbClient'
import { GraphConfig, ViewConfig, getConfigs, getViewConfig } from '../lib/storage'

export interface DataItem {
  timestamp: string
  origin: string
  _id: string
  [key: string]: number | string | boolean | number[] | string[] | boolean[]
}

export type DbState = 'init' | 'sync' | 'ready' | 'error'

interface AppState {
  configs: GraphConfig[]
  viewConfig: ViewConfig
  fetchData: (index: number) => Promise<void>
  dataCache: Array<DataItem[] | null>
  dbState: DbState[]
  lastUpdates: string[]
  reloadConfigs: () => void
  reloadViewConfig: () => void
}

let lastUpdateMs = 0

function updateData() {
  if (document.visibilityState === 'hidden' || Date.now() - lastUpdateMs < 15000) return
  lastUpdateMs = Date.now()
  const { lastUpdates, dbState, dataCache, configs } = useAppStore.getState()
  dbState.forEach(async (state, index) => {
    if (state !== 'ready') return
    const { database, password } = configs[index]
    const { events, lastUpdate } = await getChangedEvents(lastUpdates[index], database, password)
    if (!events.length) return
    console.log(`[${format(Date.now(), 'HH:mm')}|app] updating ${database} (${events.length} docs)`)
    const data = dataCache[index]
    if (!data) return
    for (const event of events) {
      const evtIndex = dataCache[index]?.findIndex(({ _id }) => _id === event._id) ?? -1
      if (evtIndex > -1) {
        if (event._deleted) {
          data.splice(evtIndex, 1)
        } else {
          data[evtIndex] = omit(event, '_deleted') as DataItem
        }
      } else {
        data.push(omit(event, '_deleted') as DataItem)
      }
    }
    dataCache[index] = [...data]
    dbState[index] = 'ready'
    lastUpdates[index] = lastUpdate
    useAppStore.setState({ dataCache: [...dataCache], dbState: [...dbState], lastUpdates })
  })
}

export const useAppStore = createWithEqualityFn<AppState>(
  (set, get) => ({
    dataCache: [],
    dbState: [],
    lastUpdates: [],
    configs: getConfigs(),
    viewConfig: getViewConfig(),
    fetchData: async (index) => {
      const { dataCache, dbState, configs, lastUpdates } = get()
      if (dbState[index]) return
      const { database, password, timespan } = configs[index]
      console.log(`[${format(Date.now(), 'HH:mm')}|app] fetching data for ${database}`)
      dataCache[index] ||= null
      dbState[index] = 'init'
      set({
        dataCache,
        dbState: [...dbState]
      })
      const { events, lastUpdate } = await getRecentEvents(database, password, timespan)
      dataCache[index] = events
      dbState[index] = 'ready'
      if (lastUpdate) lastUpdates[index] = lastUpdate
      set({ dataCache: [...dataCache], dbState: [...dbState], lastUpdates })
      console.log(
        `[${format(Date.now(), 'HH:mm')}|app] data for ${database} is ready (${dataCache[index]?.length} docs)`
      )
      lastUpdateMs = Date.now()
    },
    reloadConfigs: () => {
      set({
        configs: getConfigs()
      })
    },
    reloadViewConfig: () => {
      set({
        viewConfig: getViewConfig()
      })
    }
  }),
  shallow
)

document.addEventListener('visibilitychange', updateData)
window.addEventListener('focus', updateData)
setInterval(updateData, 60000)
