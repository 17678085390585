import { Form, Input, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { ReactElement } from 'react'
import { GraphConfig } from '../lib/storage'

export default function AddGraphModal({
  isOpen,
  onOk,
  onCancel,
}: {
  isOpen: boolean
  onOk: (config: Omit<GraphConfig, 'lines'>) => void
  onCancel: () => void
}): ReactElement {
  const [form] = useForm()

  function handleOk() {
    onOk(form.getFieldsValue())
  }

  function handleCancel() {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      title="Neuen Datenbank-Graph hinzufügen"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={960}
      okText="Speichern"
      cancelText="Abbrechen"
    >
      <Form
        form={form}
        name="edit"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 800, marginTop: 20 }}
        onFinish={handleOk}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Bitte einen Namen für den Tab eingeben',
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="database"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Bitte Datenbank-ID angeben',
            },
          ]}
        >
          <Input placeholder="Datenbank-ID" />
        </Form.Item>
        <Form.Item
          name="user"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Bitte den Benutzernamen der Datenbank eingeben',
            },
          ]}
        >
          <Input placeholder="Benutzername" />
        </Form.Item>
        <Form.Item
          name="password"
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Bitte das Passwort für die Datenbank eingeben',
            },
          ]}
        >
          <Input placeholder="Passwort" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
