import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import max from 'lodash/max'
import min from 'lodash/min'
import range from 'lodash/range'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  DomainTuple,
  Tuple,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryZoomContainer
} from 'victory'
import { GraphConfig, GraphLineConfig } from '../lib/storage'
import { DataItem } from '../stores/app'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`

const ZoomButtons = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
  top: 0;
  right: 20px;
  z-index: 100;
`

interface GraphProps {
  config: GraphConfig
  data: DataItem[]
  selectedLines: number[]
  initialZoomScale: number
  onZoomScaleChange: (span: number) => void
}

export interface Domain {
  x: Tuple<number>
  y: Tuple<number>
}

interface Datum {
  x: number
  y: string | number | boolean | string[] | number[] | boolean[]
  y0?: number
  _id: string
}

const isMobileMode = window.innerWidth < 400 || window.innerHeight < 400

const chartPadding = isMobileMode
  ? { top: 0, bottom: 20, left: 40, right: 8 }
  : { top: 20, bottom: 40, left: 40, right: 20 }

function getMinMax(data: Datum[][], lineConfig: GraphLineConfig[]): [number, number] {
  const allValues = data
    .filter((_, index) => !lineConfig[index].binary)
    .flatMap((dataStream) => dataStream.map((o) => (typeof o.y === 'number' ? o.y : 0)))
  const minValue = min(allValues) ?? 0
  const maxValue = max(allValues) ?? 0
  return [minValue, maxValue]
}

function getDomainSpread([minValue, maxValue]: [number, number]): [number, number] {
  const range = maxValue - minValue
  return [minValue - range * 0.05, maxValue + range * 0.05]
}

function isNumberDomain(domain: { x: DomainTuple; y: DomainTuple }): domain is Domain {
  return typeof domain.x[0] === 'number' && typeof domain.y[0] === 'number'
}

function applyFilterCondition(condition?: string): (item: DataItem) => boolean {
  if (!condition) return () => true
  // eslint-disable-next-line no-new-func
  const evaluator = Function('o', `return ${condition}`)
  return (item) => evaluator(item)
}

function findLeftIndex(dataSet: Array<{ x: number }>, limit: number, offset = 0): number {
  if (!dataSet[offset]) return 0
  if (offset > 0) {
    if (limit === dataSet[offset].x) return offset - 1
    if (limit < dataSet[offset].x) {
      for (let i = offset; i > 0; i--) {
        if (dataSet[i].x < limit) return i
      }
      return 0
    }
    if (limit > dataSet[offset].x) {
      for (let i = offset; i < dataSet.length - 1; i++) {
        if (dataSet[i].x > limit) return i - 1
      }
      return dataSet.length - 1
    }
  }
  const start = dataSet.findIndex((o) => o.x > limit)
  return start - 1 > 0 ? start - 1 : 0
}

function findRightIndex(dataSet: Array<{ x: number }>, limit: number, offset = 0): number {
  if (offset > dataSet.length - 1) {
    return dataSet.length - 1
  }
  if (offset > 0) {
    if (limit === dataSet[offset].x) return offset - 1
    if (limit < dataSet[offset].x) {
      for (let i = offset; i > 0; i--) {
        if (dataSet[i].x < limit) return i + 1 < dataSet.length - 1 ? i + 1 : dataSet.length - 1
      }
      return 0
    }
    if (limit > dataSet[offset].x) {
      for (let i = offset; i < dataSet.length - 1; i++) {
        if (dataSet[i].x > limit) return i
      }
      return dataSet.length - 1
    }
  }
  const start = dataSet.findIndex((o) => o.x > limit)
  return start < 0 ? dataSet.length - 1 : start
}

function downSample<T>(data: T[], n = 250): T[] {
  if (data.length <= n) return data
  const sampleFactor = Math.floor(data.length / n)
  return data.filter((_item, index) => index % sampleFactor === 0)
}

function prepareBinary(data: Datum[], domain: Domain): Datum[] {
  const [minX, maxX] = domain.x
  const [minY, maxY] = domain.y

  const dataChanges = data.filter((datum, index) => {
    if (index === 0 || index === data.length - 1) return true
    const previous = data[index - 1]
    return datum.y !== previous.y
  })
  return dataChanges.flatMap((datum, index) => {
    if (index === 0)
      return [
        { ...datum, x: minX, y: datum.y ? maxY : minY, y0: minY },
        { ...datum, y: datum.y ? maxY : minY, y0: minY }
      ]
    const previous = dataChanges[index - 1]
    const result = [
      {
        ...previous,
        x: datum.x - 1,
        y: previous.y ? maxY : minY,
        y0: minY
      },
      {
        ...datum,
        y: datum.y ? maxY : minY,
        y0: minY
      }
    ]
    if (index === dataChanges.length - 1) {
      result.push({
        ...datum,
        x: maxX,
        y: datum.y ? maxY : minY,
        y0: minY
      })
    }
    return result
  })
}

export default function Graph({
  config,
  data,
  selectedLines,
  initialZoomScale,
  onZoomScaleChange
}: GraphProps): ReactElement | null {
  if (!config.lines.length) return <Container />
  const graphData = useMemo(
    () =>
      config.lines.map((lineConfig) =>
        data.filter(applyFilterCondition(lineConfig.filterCondition)).map((item) => ({
          x: new Date(item.timestamp).getTime(),
          y: item[lineConfig.property],
          _id: item._id
        }))
      ),
    [data, config.lines]
  )
  const domain: Domain = useMemo(
    () => ({
      x: [
        min(graphData.filter((o) => o.length).map((o) => o[0].x)) ?? 0,
        (max(graphData.filter((o) => o.length).map((o) => last(o)?.x ?? 0)) ?? 0) + 1000
      ] as Tuple<number>,
      y: getDomainSpread(getMinMax(graphData, config.lines))
    }),
    [graphData, config.lines]
  )

  const [zoomDomain, setZoomDomain] = useState<Domain>({
    x: [domain.x[1] - initialZoomScale, domain.x[1]],
    y: [domain.y[0], domain.y[1]]
  })

  const [dataWindows, setDataWindows] = useState<Array<[number, number]>>(
    graphData.map((stream) => {
      const left = findLeftIndex(stream, zoomDomain.x[0])
      const right = findRightIndex(stream, zoomDomain.x[1])
      return [left, right] as Tuple<number>
    })
  )
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>()

  const ref = useRef<HTMLDivElement>(null)

  const handleChartDimensions = useCallback(
    function handleChartDimensions(): void {
      if (!ref.current) return
      const { width, height } = ref.current.getBoundingClientRect()
      const newDimensions = { width, height: height - 36 }
      if (!isEqual(newDimensions, dimensions)) setDimensions(newDimensions)
    },
    [dimensions]
  )

  useEffect(() => {
    window.addEventListener('resize', handleChartDimensions)
    return () => {
      window.removeEventListener('resize', handleChartDimensions)
    }
  }, [handleChartDimensions])

  useEffect(() => {
    if (!selectedLines.length) return
    setZoomDomain((previous) => ({
      x: previous.x,
      y: getDomainSpread(getMinMax(graphData, config.lines))
    }))
  }, [graphData, config.lines, selectedLines])

  const tickValues = useMemo(() => {
    const days = differenceInDays(zoomDomain.x[1], zoomDomain.x[0])
    if (days > 1) {
      const interval = days > 3 ? 86400000 : 43200000
      let start = startOfDay(zoomDomain.x[0]).getTime() + interval
      if (start < zoomDomain.x[0]) start += interval
      return downSample(range(start, zoomDomain.x[1], interval), 6)
    }
    const hours = differenceInHours(zoomDomain.x[1], zoomDomain.x[0])
    const interval = hours > 24 ? 3600000 * 6 : hours > 12 ? 3600000 * 4 : hours > 6 ? 3600000 * 2 : 3600000
    let start = startOfDay(zoomDomain.x[0]).getTime()
    while (start < zoomDomain.x[0]) start += interval
    return range(start, zoomDomain.x[1], interval)
  }, [zoomDomain.x])

  const dataPartitions = useMemo(() => {
    if (!dataWindows.length) return []
    return graphData.map((stream, index) => {
      const dataSlice = dataWindows[index] ? stream.slice(dataWindows[index][0], dataWindows[index][1] + 1) : []
      const reducedData = config.lines[index].binary ? prepareBinary(dataSlice, domain) : downSample(dataSlice)
      return { data: reducedData, isFullResolution: reducedData.length === dataSlice.length }
    })
  }, [domain, graphData, config.lines, dataWindows])

  function onZoomDomainChange(changedDomain: { x: DomainTuple; y: DomainTuple }): void {
    if (
      (changedDomain.x[0] === zoomDomain.x[0] && changedDomain.x[1] === zoomDomain.x[1]) ||
      changedDomain.x[0] === changedDomain.x[1] ||
      !isNumberDomain(changedDomain)
    )
      return
    onZoomScaleChange(changedDomain.x[1] - changedDomain.x[0])
    const newWindows = graphData.map((stream, index) => {
      const left = findLeftIndex(stream, changedDomain.x[0], dataWindows[index]?.[0])
      const right = findRightIndex(stream, changedDomain.x[1], dataWindows[index]?.[1])
      return [left, right] as Tuple<number>
    })
    setZoomDomain(changedDomain)
    setDataWindows(newWindows)
  }

  function formatX(millis: number): string {
    if (!millis) return '0'
    if (startOfDay(millis).getTime() === millis) return format(millis, 'dd.MM.')
    return format(millis, 'HH:mm')
  }

  if (data.length < 1) return null

  handleChartDimensions()

  return (
    <Container ref={ref}>
      {isMobileMode && (
        <ZoomButtons>
          <Button
            icon={<PlusOutlined />}
            size="small"
            shape="circle"
            onClick={() => {
              const change = Math.floor((zoomDomain.x[1] - zoomDomain.x[0]) * 0.2)
              onZoomDomainChange({ x: [zoomDomain.x[0] + change, zoomDomain.x[1] - change], y: zoomDomain.y })
            }}
          />
          <Button
            icon={<MinusOutlined />}
            size="small"
            shape="circle"
            onClick={() => {
              const change = Math.floor((zoomDomain.x[1] - zoomDomain.x[0]) * 0.2)
              onZoomDomainChange({ x: [zoomDomain.x[0] - change, zoomDomain.x[1] + change], y: zoomDomain.y })
            }}
          />
        </ZoomButtons>
      )}
      {dimensions && (
        <VictoryChart
          padding={chartPadding}
          width={dimensions.width}
          height={dimensions.height}
          domain={domain}
          containerComponent={
            <VictoryZoomContainer
              name="zoom-container"
              zoomDimension="x"
              onZoomDomainChange={onZoomDomainChange}
              zoomDomain={zoomDomain}
              allowPan={true}
              allowZoom={true}
            />
          }
          theme={VictoryTheme.material}
        >
          <VictoryAxis dependentAxis />
          <VictoryAxis tickFormat={formatX} tickValues={tickValues} />
          {config.lines.map(
            (lineConfig, index) =>
              selectedLines.includes(index) &&
              lineConfig.binary && (
                <VictoryArea
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={`ar_${index}`}
                  data={dataPartitions[index]?.data ?? []}
                  style={{ data: { stroke: 'transparent', fill: lineConfig.color, fillOpacity: 0.1 } }}
                />
              )
          )}
          {config.lines.map(
            (lineConfig, index) =>
              selectedLines.includes(index) &&
              !lineConfig.binary && (
                <VictoryLine
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={`ln_${index}`}
                  data={dataPartitions[index]?.data ?? []}
                  interpolation="monotoneX"
                  style={{ data: { stroke: lineConfig.color } }}
                />
              )
          )}
          {config.lines.map(
            (lineConfig, index) =>
              selectedLines.includes(index) &&
              !lineConfig.binary && (
                <VictoryScatter
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={`sc_${index}`}
                  data={
                    dataPartitions[index]?.data && dataPartitions[index]?.data.length < 100
                      ? dataPartitions[index].data
                      : []
                  }
                  size={isMobileMode ? 5 : 3}
                  labelComponent={
                    <VictoryTooltip
                      labelComponent={<VictoryLabel lineHeight={1.5} />}
                      flyoutStyle={{ strokeWidth: 1, stroke: lineConfig.color, fill: '#fff' }}
                      style={{ strokeWidth: 2 }}
                      flyoutPadding={10}
                      pointerLength={8}
                      constrainToVisibleArea
                    />
                  }
                  labels={({ datum: { x, y } }: { datum: { x: number; y: number } }) =>
                    `${format(new Date(x), 'dd. MM. yyyy HH:mm')} | ${y}`
                  }
                  style={{
                    data: {
                      stroke: lineConfig.color,
                      strokeWidth: 2,
                      fill: '#fff'
                    }
                  }}
                  events={[
                    {
                      childName: 'all',
                      target: 'data',
                      eventHandlers: {
                        onClick: (_, { datum }) => console.log(datum)
                      }
                    }
                  ]}
                />
              )
          )}
        </VictoryChart>
      )}
    </Container>
  )
}
